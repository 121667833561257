var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-alert",
        {
          attrs: { variant: "danger", dismissible: "" },
          model: {
            value: _vm.showAlert,
            callback: function ($$v) {
              _vm.showAlert = $$v
            },
            expression: "showAlert",
          },
        },
        [_c("b", [_vm._v(_vm._s(_vm.alertMessage))])]
      ),
      !_vm.report_selected
        ? _c(
            "div",
            { staticClass: "d-flex mb-4" },
            [
              !_vm.report_selected && !_vm.idType
                ? _c(
                    "div",
                    {
                      staticClass: "avo-page-header-text",
                      staticStyle: { "margin-right": "30px" },
                    },
                    [_vm._v(" Reports ")]
                  )
                : _vm._e(),
              !_vm.report_selected
                ? _c(
                    "div",
                    { staticStyle: { "margin-right": "30px" } },
                    [
                      _vm.standard_tab
                        ? _c(
                            "b-input-group",
                            { staticClass: "search-group" },
                            [
                              _c("b-input-group-prepend", [
                                _c(
                                  "span",
                                  { staticClass: "input-group-text" },
                                  [_c("b-icon", { attrs: { icon: "search" } })],
                                  1
                                ),
                              ]),
                              _c("b-form-input", {
                                staticClass: "report-search-input pl-1",
                                attrs: {
                                  type: "search",
                                  placeholder: "Search standard reports",
                                },
                                on: {
                                  keyup: _vm.debounceReports,
                                  search: _vm.debounceReports,
                                },
                                model: {
                                  value: _vm.standard_search,
                                  callback: function ($$v) {
                                    _vm.standard_search = $$v
                                  },
                                  expression: "standard_search",
                                },
                              }),
                            ],
                            1
                          )
                        : _c(
                            "b-input-group",
                            { staticClass: "search-group" },
                            [
                              _c("b-input-group-prepend", [
                                _c(
                                  "span",
                                  { staticClass: "input-group-text" },
                                  [_c("b-icon", { attrs: { icon: "search" } })],
                                  1
                                ),
                              ]),
                              _c("b-form-input", {
                                staticClass: "report-search-input pl-1",
                                attrs: {
                                  type: "search",
                                  placeholder: "Search custom reports",
                                },
                                on: {
                                  keyup: _vm.debounceCustomReports,
                                  search: _vm.debounceCustomReports,
                                },
                                model: {
                                  value: _vm.custom_search,
                                  callback: function ($$v) {
                                    _vm.custom_search = $$v
                                  },
                                  expression: "custom_search",
                                },
                              }),
                            ],
                            1
                          ),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.report_selected && !_vm.idType
                ? _c(
                    "b-nav",
                    { staticClass: "nav-section", attrs: { tabs: "" } },
                    [
                      _c(
                        "b-nav-item",
                        {
                          attrs: { active: _vm.standard_tab },
                          on: {
                            click: function ($event) {
                              _vm.standard_tab = true
                            },
                          },
                        },
                        [_vm._v("Standard")]
                      ),
                      _c(
                        "b-nav-item",
                        {
                          attrs: { active: !_vm.standard_tab },
                          on: {
                            click: function ($event) {
                              _vm.standard_tab = false
                            },
                          },
                        },
                        [_vm._v("Custom Reports")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c("report-assignment-modal"),
      _c("report-document-assignment-modal"),
      _c(
        "b-container",
        { staticStyle: { "max-width": "100%", padding: "30px" } },
        [
          _vm.idType && !_vm.report_selected ? _c("br") : _vm._e(),
          _vm.standard_tab && !_vm.report_selected
            ? _c(
                "div",
                {
                  staticStyle: {
                    "margin-right": "-20px",
                    "margin-left": "-20px",
                  },
                },
                [
                  _c(
                    "b-card",
                    {
                      staticStyle: {
                        "border-radius": "15px",
                        "min-height": "500px",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "avo-header-text report-container-header",
                        },
                        [_vm._v(" Standard Reports ")]
                      ),
                      _c(
                        "b-overlay",
                        {
                          attrs: {
                            show: _vm.standardLoading,
                            variant: "white",
                          },
                        },
                        _vm._l(
                          Object.keys(_vm.standard_reports),
                          function (report_key, index) {
                            return _c(
                              "b-row",
                              {
                                key: index,
                                staticStyle: {
                                  padding: "30px",
                                  "padding-bottom": "0px",
                                },
                              },
                              [
                                _c(
                                  "b-col",
                                  {
                                    staticClass: "d-flex flex-column flex-fill",
                                    attrs: { cols: "6" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "avo-header-text" },
                                      [_vm._v(" " + _vm._s(report_key) + " ")]
                                    ),
                                  ]
                                ),
                                _c(
                                  "b-col",
                                  {
                                    staticClass: "d-flex flex-column flex-fill",
                                    attrs: { cols: "6" },
                                  },
                                  _vm._l(
                                    _vm.standard_reports[report_key],
                                    function (report, report_index) {
                                      return _c(
                                        "div",
                                        { key: report_index },
                                        [
                                          _c(
                                            "b-dropdown",
                                            {
                                              staticClass:
                                                "avo-action-dropdown",
                                              staticStyle: {
                                                "min-width": "none",
                                                height: "20px",
                                                "margin-top": "-20px",
                                              },
                                              attrs: {
                                                "no-caret": "",
                                                right: true,
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "button-content",
                                                    fn: function () {
                                                      return [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticStyle: {
                                                              "font-size":
                                                                "16px",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                report["title"]
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [
                                              _c(
                                                "b-dropdown-item",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.editReport(
                                                        report
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("Modify")]
                                              ),
                                              _c(
                                                "b-dropdown-item",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.selectReport(
                                                        report
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("View CSV exports")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c("hr"),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ],
                              1
                            )
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm.report_selected
            ? _c(
                "div",
                {
                  staticStyle: {
                    "margin-right": "-20px",
                    "margin-left": "-20px",
                  },
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "avo-basic-btn mt-3 mb-3",
                      attrs: { variant: "outline-primary" },
                      on: {
                        click: function ($event) {
                          return _vm.selectReport(null)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "fas fa-chevron-left mr-2" }),
                      _vm._v(" Back to reports"),
                    ]
                  ),
                  _c("report-card", {
                    attrs: {
                      report: _vm.selected_report,
                      showKebab: false,
                      exportOnly: !!_vm.idType,
                    },
                    on: {
                      exported: function ($event) {
                        return _vm.exportReport()
                      },
                      edit: function ($event) {
                        return _vm.editReport(_vm.selected_report)
                      },
                    },
                  }),
                  _c("document-list", {
                    key: _vm.document_list_key,
                    attrs: {
                      idType: "report",
                      id: _vm.selected_report.report_id,
                      perPage: "6",
                      mode: "table",
                      hideDropdown: !!_vm.idType,
                    },
                  }),
                ],
                1
              )
            : _c(
                "div",
                {
                  staticStyle: {
                    "margin-right": "-20px",
                    "margin-left": "-20px",
                  },
                },
                [
                  _c(
                    "b-card",
                    {
                      staticStyle: {
                        "border-radius": "15px",
                        "min-height": "500px",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-between report-container-header",
                        },
                        [
                          _c("div", { staticClass: "avo-header-text" }, [
                            _vm._v(" Custom Reports "),
                          ]),
                          _c(
                            "div",
                            [
                              !_vm.idType
                                ? _c(
                                    "b-button",
                                    {
                                      directives: [
                                        {
                                          name: "b-modal",
                                          rawName: "v-b-modal.report-settings",
                                          modifiers: {
                                            "report-settings": true,
                                          },
                                        },
                                      ],
                                      staticClass: "avo-primary-btn",
                                    },
                                    [
                                      _c(
                                        "b-overlay",
                                        {
                                          attrs: {
                                            show: _vm.customMapLoading,
                                            variant: "white",
                                          },
                                        },
                                        [_vm._v(" Create New Report ")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c("report-settings-modal"),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "b-overlay",
                        {
                          attrs: { show: _vm.customLoading, variant: "white" },
                        },
                        [
                          _vm.recent_reports.length > 0
                            ? _c(
                                "div",
                                {
                                  staticClass: "mt-2",
                                  staticStyle: { padding: "30px" },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "avo-header-text" },
                                    [_vm._v(" Recently Created ")]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "d-flex flex-wrap mt-4" },
                                    _vm._l(
                                      _vm.recent_reports,
                                      function (report, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: report.report_id,
                                            class: index > 1 ? "mt-4" : "",
                                            staticStyle: { width: "50%" },
                                          },
                                          [
                                            _c("report-card", {
                                              class:
                                                index % 2 ? "ml-4" : "mr-4",
                                              attrs: {
                                                report: report,
                                                exportOnly: !!_vm.idType,
                                              },
                                              on: {
                                                selected: function ($event) {
                                                  return _vm.selectReport(
                                                    report
                                                  )
                                                },
                                                edit: function ($event) {
                                                  return _vm.editReport(report)
                                                },
                                                updated: function ($event) {
                                                  return _vm.getCustomReports()
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.last_month_reports.length > 0
                            ? _c(
                                "div",
                                {
                                  staticClass: "mt-2",
                                  staticStyle: { padding: "30px" },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "avo-header-text" },
                                    [_vm._v(" Created Last Month ")]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "d-flex flex-wrap mt-4" },
                                    _vm._l(
                                      _vm.last_month_reports,
                                      function (report, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: report.report_id,
                                            class: index > 1 ? "mt-4" : "",
                                            staticStyle: { width: "50%" },
                                          },
                                          [
                                            _c("report-card", {
                                              class:
                                                index % 2 ? "ml-4" : "mr-4",
                                              attrs: {
                                                report: report,
                                                exportOnly: !!_vm.idType,
                                              },
                                              on: {
                                                selected: function ($event) {
                                                  return _vm.selectReport(
                                                    report
                                                  )
                                                },
                                                edit: function ($event) {
                                                  return _vm.editReport(report)
                                                },
                                                updated: function ($event) {
                                                  return _vm.getCustomReports()
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.before_last_month_reports.length > 0
                            ? _c(
                                "div",
                                {
                                  staticClass: "mt-2",
                                  staticStyle: { padding: "30px" },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "avo-header-text" },
                                    [_vm._v(" Created before Last Month ")]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "d-flex flex-wrap mt-4" },
                                    _vm._l(
                                      _vm.before_last_month_reports,
                                      function (report, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: report.report_id,
                                            class: index > 1 ? "mt-4" : "",
                                            staticStyle: { width: "50%" },
                                          },
                                          [
                                            _c("report-card", {
                                              class:
                                                index % 2 ? "ml-4" : "mr-4",
                                              attrs: {
                                                report: report,
                                                exportOnly: !!_vm.idType,
                                              },
                                              on: {
                                                selected: function ($event) {
                                                  return _vm.selectReport(
                                                    report
                                                  )
                                                },
                                                edit: function ($event) {
                                                  return _vm.editReport(report)
                                                },
                                                updated: function ($event) {
                                                  return _vm.getCustomReports()
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.recent_reports.length == 0 &&
                          _vm.last_month_reports.length == 0 &&
                          _vm.before_last_month_reports.length == 0
                            ? _c(
                                "div",
                                {
                                  staticClass: "avo-header-text mt-2",
                                  staticStyle: {
                                    padding: "30px",
                                    "text-align": "center",
                                  },
                                },
                                [_vm._v(" No Reports Found ")]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }