var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "report-assignments" } },
    [
      _c(
        "b-modal",
        {
          attrs: {
            id: "report-assignments",
            title: "Report Assignments",
            "hide-footer": "",
            size: "md",
          },
          on: { show: _vm.setForm },
        },
        [
          _c(
            "b-form",
            [
              _c(
                "b-alert",
                {
                  attrs: { variant: "danger", dismissible: "" },
                  model: {
                    value: _vm.showAlert,
                    callback: function ($$v) {
                      _vm.showAlert = $$v
                    },
                    expression: "showAlert",
                  },
                },
                [_c("b", [_vm._v(_vm._s(_vm.alertMessage))])]
              ),
              _c("div", [
                _c(
                  "div",
                  [
                    _c(
                      "b-form-group",
                      { attrs: { label: "Assign Practices:" } },
                      [
                        !_vm.practicesLoading
                          ? _c("practice-multiselect", {
                              attrs: {
                                multiple: true,
                                loading: _vm.practicesLoading,
                              },
                              model: {
                                value: _vm.selectedPractices,
                                callback: function ($$v) {
                                  _vm.selectedPractices = $$v
                                },
                                expression: "selectedPractices",
                              },
                            })
                          : _c(
                              "div",
                              { staticStyle: { "text-align": "center" } },
                              [_c("b-spinner")],
                              1
                            ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c(
                      "b-form-group",
                      { attrs: { label: "Assign Providers:" } },
                      [
                        !_vm.providersLoading
                          ? _c("provider-multiselect", {
                              attrs: {
                                multiple: true,
                                loading: _vm.providersLoading,
                              },
                              model: {
                                value: _vm.selectedProviders,
                                callback: function ($$v) {
                                  _vm.selectedProviders = $$v
                                },
                                expression: "selectedProviders",
                              },
                            })
                          : _c(
                              "div",
                              { staticStyle: { "text-align": "center" } },
                              [_c("b-spinner")],
                              1
                            ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "mt-4",
                    staticStyle: { "text-align": "right" },
                  },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "avo-basic-btn mr-2",
                        on: {
                          click: function ($event) {
                            return _vm.hideModal()
                          },
                        },
                      },
                      [_vm._v(" Cancel ")]
                    ),
                    _c(
                      "b-button",
                      {
                        staticClass: "avo-primary-btn",
                        attrs: {
                          disabled:
                            _vm.practicesLoading || _vm.providersLoading,
                          variant: "outline-primary",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.unassignPractices()
                          },
                        },
                      },
                      [_vm._v(" Submit Report Assignments ")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }