var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-card", [
    _c("div", { staticClass: "d-flex justify-content-between" }, [
      _c("div", { staticClass: "avo-header-text" }, [
        _vm._v(" " + _vm._s(_vm.report.title) + " "),
      ]),
      _c(
        "div",
        [
          !_vm.showKebab
            ? _c(
                "div",
                [
                  !_vm.exportOnly && !_vm.report.standard
                    ? _c(
                        "b-button",
                        {
                          staticClass: "avo-primary-btn mr-2",
                          on: {
                            click: function ($event) {
                              return _vm.viewReportAssignmentModal()
                            },
                          },
                        },
                        [_vm._v("Manage Report Assignments")]
                      )
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      staticClass: "avo-primary-btn",
                      on: {
                        click: function ($event) {
                          return _vm.exportReport()
                        },
                      },
                    },
                    [_vm._v("Run CSV export")]
                  ),
                  _c(
                    "b-modal",
                    {
                      ref: "report-exporting-modal",
                      attrs: { "hide-footer": "", title: "Report exporting" },
                    },
                    [
                      _c("div", { staticClass: "d-block text-center" }, [
                        _vm._v(
                          " Your report is processing in the background, you may navigate away. Your report will appear here when ready. "
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.showKebab
            ? _c(
                "b-dropdown",
                {
                  staticClass: "avo-action-dropdown",
                  staticStyle: {
                    "min-width": "none",
                    height: "20px",
                    "margin-top": "-20px",
                  },
                  attrs: { "no-caret": "", right: true },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "button-content",
                        fn: function () {
                          return [
                            _c("b-icon", { attrs: { icon: "three-dots" } }),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    2038261878
                  ),
                },
                [
                  !_vm.exportOnly
                    ? _c(
                        "b-dropdown-item",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.renameReport()
                            },
                          },
                        },
                        [_vm._v("Rename")]
                      )
                    : _vm._e(),
                  !_vm.exportOnly
                    ? _c(
                        "b-dropdown-item",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.editReport()
                            },
                          },
                        },
                        [_vm._v("Edit")]
                      )
                    : _vm._e(),
                  !_vm.exportOnly
                    ? _c(
                        "b-dropdown-item",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.cloneReport()
                            },
                          },
                        },
                        [_vm._v("Clone")]
                      )
                    : _vm._e(),
                  _c(
                    "b-dropdown-item",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.selectReport()
                        },
                      },
                    },
                    [_vm._v("View CSV exports")]
                  ),
                  !_vm.exportOnly
                    ? _c(
                        "b-dropdown-item",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.deleteReport()
                            },
                          },
                        },
                        [_vm._v("Delete")]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-modal",
            {
              attrs: {
                id: "rename-modal-" + _vm.report.report_id,
                "hide-footer": "",
                "hide-header": "",
                size: "md",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "avo-header-text",
                  staticStyle: { "text-align": "center" },
                },
                [_vm._v(" Rename '" + _vm._s(_vm.report.title) + "' ")]
              ),
              _c("hr", { staticClass: "mt-2" }),
              _c(
                "div",
                { staticClass: "mt-2" },
                [
                  _c(
                    "b-alert",
                    {
                      attrs: { variant: "danger", dismissible: "" },
                      model: {
                        value: _vm.showAlert,
                        callback: function ($$v) {
                          _vm.showAlert = $$v
                        },
                        expression: "showAlert",
                      },
                    },
                    [_c("b", [_vm._v(_vm._s(_vm.alertMessage))])]
                  ),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c(
                    "b-form-group",
                    { attrs: { id: "title", label: "Title" } },
                    [
                      _c(
                        "b-input-group",
                        [
                          _c("b-form-input", {
                            model: {
                              value: _vm.newTitle,
                              callback: function ($$v) {
                                _vm.newTitle = $$v
                              },
                              expression: "newTitle",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c(
                    "b-form-group",
                    { attrs: { id: "description", label: "Description" } },
                    [
                      _c(
                        "b-input-group",
                        [
                          _c("b-form-textarea", {
                            model: {
                              value: _vm.newDescription,
                              callback: function ($$v) {
                                _vm.newDescription = $$v
                              },
                              expression: "newDescription",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "d-flex justify-content-end" }, [
                _c(
                  "div",
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "avo-basic-btn",
                        on: {
                          click: function ($event) {
                            return _vm.hideRename()
                          },
                        },
                      },
                      [_vm._v("Cancel")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "ml-2" },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "avo-primary-btn",
                        on: {
                          click: function ($event) {
                            return _vm.submitRename()
                          },
                        },
                      },
                      [_vm._v("Rename Report")]
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ],
        1
      ),
    ]),
    _vm.report.description
      ? _c("div", { staticClass: "avo-text-light-small mt-2" }, [
          _vm._v(" " + _vm._s(_vm.report.description) + " "),
        ])
      : _vm._e(),
    _c("hr"),
    _c("div", { staticClass: "d-flex" }, [
      _c("div", { staticClass: "d-flex flex-column mr-2" }, [
        _c("div", { staticClass: "avo-text-light" }, [_vm._v(" Created by ")]),
        _c(
          "div",
          [
            _c("b-avatar", {
              attrs: { text: _vm.report.initials, size: "20px" },
            }),
            _vm._v(" " + _vm._s(_vm.report.name) + " "),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "d-flex flex-column ml-2" }, [
        _c("div", { staticClass: "avo-text-light" }, [
          _vm._v(" Last updated by "),
        ]),
        _c(
          "div",
          [
            _c("b-avatar", {
              attrs: {
                text: _vm.report.initials,
                "report.initials": "",
                size: "20px",
              },
            }),
            _vm._v(
              " " +
                _vm._s(_vm.report.name) +
                " at " +
                _vm._s(_vm.datetimeFormat(_vm.report.updated)) +
                " "
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }